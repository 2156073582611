document.addEventListener('turbolinks:load', () => {

  // Define the toggleAccordion function globally to allow HTML onclick calls
  window.toggleAccordion = function(index) {
    var content = $('#content-' + index);
    var chevronIcon = $('#icon-' + index);

    // Toggle the content's hidden class
    if (content.hasClass('hidden')) {
      // Expand the section
      content.removeClass('hidden');
      chevronIcon.removeClass('rotate-[180deg]');
    } else {
      // Collapse the section
      content.addClass('hidden');
      chevronIcon.addClass('rotate-[180deg]');
    }
  };

  // Check if the modal exists before firing openModal
  if ($('#verifiedClickthruModal').length) {
    openModal('#verifiedClickthruModal');
  }
});